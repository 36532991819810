import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
	selector: '[appFullHeight]',
})
export class FullHeightDirective implements OnDestroy, OnInit {
	private ele: ElementRef;
	private $subs: Subscription;
	constructor(el: ElementRef) {
		this.ele = el;
		this.$subs = fromEvent(window, 'resize')
			.pipe(debounceTime(1500))
			.subscribe((x) => {
				this.adjustHeight((x.target as Window).innerHeight);
			});
	}

	ngOnInit(): void {
		this.adjustHeight(window.innerHeight);
	}

	ngOnDestroy(): void {
		this.$subs.unsubscribe();
	}
	/**
	 * adjustHeight
	 *
	 * @description Method for chance de height of a element
	 * @param height - Height to set an element
	 */
	public adjustHeight(height: number): void {
		this.ele.nativeElement.style.minHeight = `${height}px`;
	}
}
