export const environment = {
	production: false,
	apiUrl: 'https://portaldes.bancaempresasdescal.pichincha.pe/api',
	microApps: 'https://portaldes.bancaempresasdescal.pichincha.pe/assets/microapp-dev.json',
	mainPath: '/consolidate-position',
	timeSession: 300,
	psswdExpireTime: '7776000',
	psswdMinTimeAlert: '15',
	authProvider: {
		clientId: '6ef600c5-310a-4611-b3d2-d97078fe8342',
		tenantId: 'c7b4d308-3359-4d1a-800f-27ead5d16583',
		tenantName: 'b2cbusinessbankingpedev.onmicrosoft.com',
		domain: 'logindes.bancaempresasdescal.pichincha.pe',
		autority: 'https://logindes.bancaempresasdescal.pichincha.pe/accountdes.bancaempresasdescal.pichincha.pe/B2C_1A_BUSINESSBANKING_SIGNIN',
		redirectUrl: 'https://portaldes.bancaempresasdescal.pichincha.pe',
		accessTokenName: 'access_token',
		authToken: 'auth_token',
		scopes: ['openid https://accountdes.bancaempresasdescal.pichincha.pe/6ef600c5-310a-4611-b3d2-d97078fe8342/users.read'],
		Apikey: 'b293a52daa62487695559e92b4c3258e',
	},
	storage: {
		key: 'Z&RbJGC9Ba',
		apiKey: 'api_key',
		appName: 'app_name',
		microFront: 'micro_front',
		sessionId: 'uuid',
		language: 'lang',
	},
	google: {
		tagManagerId: 'GTM-5H7SZZH',
		captchaSiteKey: '6LcwhmImAAAAAK482AAk9PHZbD1rNRlZLTgo2Ui-',
	},
	translationFiles: 'https://portaldes.bancaempresasdescal.pichincha.pe/assets/locales/',
	hotJar: '1370437',
	websocketUrl:
		'https://portaldes.bancaempresasdescal.pichincha.pe/api/channel/business-banking/v1/session-socket/session?subscription-key=820f9fac0adb448ab652076da7b2ebdd',
	guideTour: {
		counter: '2',
	},
	websocketConfig: {
		numberOfRetries: '3',
		logout: '0',
	},
};
