import { Injectable } from '@angular/core';

import { StorageService } from '@pichincha/angular-sdk/storage';

import Stomp from 'stompjs';
import SockJS from 'sockjs-client';

import { Subject } from 'rxjs';
import { shareReplay, startWith } from 'rxjs/operators';

import { UserService } from '../user.service';

import { environment } from '../../../../environments/environment';
import { WebSocketActions } from '../../config/web-socket.enum';
import { SocketAction } from '../../types/socket-action.type';

@Injectable({
	providedIn: 'root',
})
export class WebSocketService {
	private _sessionModal = new Subject<boolean>();
	sessionModal$ = this._sessionModal.asObservable().pipe(startWith(false), shareReplay(1));

	private _counter = 1;
	private _stompClient?: Stomp.Client;

	constructor(
		private _storageSrv: StorageService,
		private _userSrv: UserService,
	) {}

	async connect() {
		const uuid = this._getUserUuid();

		this.disconnect();

		const stompClient = await this._getStompInstance();

		if (stompClient) stompClient.debug = () => {};

		stompClient!.ws.addEventListener('close', (_e) => {
			this._counter++;

			if (this._counter > Number(environment.websocketConfig.numberOfRetries)) {
				Boolean(Number(environment.websocketConfig.logout)) && this._userSrv.logout();
				return;
			}

			this.connect();
		});

		this._subscribeToRoom(stompClient, uuid);

		this._stompClient = stompClient;

		return stompClient;
	}

	disconnect() {
		this._stompClient?.ws.close();
	}

	private async _getWebSocketInstance(): Promise<WebSocket> {
		return new SockJS(environment.websocketUrl);
	}

	private async _getStompInstance() {
		try {
			const sockInstance = await this._getWebSocketInstance();

			return Stomp.over(sockInstance);
		} catch (error) {
			console.warn(error);

			return undefined;
		}
	}

	private _getUserUuid() {
		try {
			const jwtToken = this._storageSrv.get(environment.authProvider.accessTokenName);
			const uuid = JSON.parse(atob(jwtToken?.split('.')[1])).uuid;

			return uuid;
		} catch (error) {
			return '';
		}
	}

	private _subscribeToRoom(stompClient: Stomp.Client | undefined, uuid: string) {
		if (!uuid) return;

		stompClient!.connect(
			{},
			(_frame) => {
				stompClient?.subscribe(`/topic/room/${uuid}`, (msg) => {
					const body = JSON.parse(msg.body);

					this._setAction(body.action);
				});
			},
			(_err) => {
				// console.warn('err', { err });
			},
		);
	}

	private _setAction(action?: SocketAction) {
		if (!action) return;

		console.log("SESSION");
		console.log(action);

		if (action === WebSocketActions.CHANGE_STATE) {
			this._userSrv.logoutWithoutService();
		}

		if (action === WebSocketActions.DOUBLE_SESSION) {
			this._sessionModal.next(true);
		}
	}
}
